<template>
  <!--start authentification dialog-->
  <v-dialog
      v-model="visible" @click:outside="visible=false"
      persistent
      overlay-color="black" scrollable
      max-width="370px"
      :fullscreen="this.$vuetify.breakpoint.xsOnly"
      class="white mx-auto justify-center align-center " style="overflow: hidden !important;"
  >
    <v-card
        tile
        elevation="0" class="pa-0"
    >
      <v-card-title class="text-right px-1 py-1">
        <span></span>

        <v-spacer></v-spacer>

        <v-btn icon @click="visible = !visible;authStep='welcome'">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text class="pa-0 mx-auto justify-center align-center" >
        <v-window v-model="authStep" touchless vertical>
          <v-window-item :value="'welcome'"  >
            <v-card
                tile
                elevation="0"
                fill-height
                color="white"
                class="pt-6 pb-10 mx-auto justify-center align-center"
            >
              <div class="pb-5 mx-auto justify-center">
                <img
                    :src="require('@/assets/img/Gruppe 2466.svg')"
                    width="88%"
                    class="mx-auto justify-center"
                />
              </div>

              <div>
                <h6 class="text-center caption font-weight-bold">
                  {{$t('delivery.lang_fastestwayToOrderFood')}}
                </h6>
              </div>

              <div class="mx-16">
                <v-btn
                    @click="authStep = 'register'"
                    color="primary"
                    class="text-capitalize rounded-lg my-6"
                    large
                    depressed
                    block
                >
                  {{$t('delivery.lang_Register')}}
                </v-btn>

                <v-btn
                    @click="authStep = 'login'"
                    class="text-capitalize rounded-lg"
                    outlined
                    large
                    depressed
                    block
                >
                  {{$t('delivery.lang_login')}}
                </v-btn>
              </div>
            </v-card>
          </v-window-item>

          <v-window-item :value="'login'"  >
            <!--start login-->
            <login @logged="visible = false" @show='authStep=$event' />
            <!--end login-->
          </v-window-item>

          <v-window-item :value="'register'"  >
            <!--start register-->
            <register @registered="authStep = 'success'" @show='authStep=$event' />
            <!--end register-->
          </v-window-item>

          <v-window-item :value="'forgetPassword'"  >
            <!--start forgot-password-->
            <forgot-password @close="visible=false;authStep='login'" />
            <!--end forgot-password-->
          </v-window-item>

          <v-window-item :value="'success'"  >
            <!--start succes-auth-->
            <SuccesAuth />
            <!--end succes-auth-->
          </v-window-item>
        </v-window>
      </v-card-text>
    </v-card>
  </v-dialog>
  <!--end authentification dialog-->
</template>

<script>
import Login from "./Login";
import ForgotPassword from "./ForgotPassword";
import Register from "./Register";
import SuccesAuth from "./SuccesAuth";
export default {
  name: "AuthDialogComponent",
  props:{
    dialog: {
      type: Boolean,
      required: true,
    },
    step: {
      type: String,
      required: false,
      default:'welcome',
    },
  },
  components: {SuccesAuth, Register, ForgotPassword, Login},
  data(){
    return{
      authStep:this.step,
    }
  },

  computed:{
    visible: {
      get() {
        return this.dialog;
      },
      set(val) {
        this.$emit("input", val);
      },
    },

  },
  mounted() {
    this.authStep=this.step;
  }
}
</script>

<style scoped>

</style>