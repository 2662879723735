<template>
  <!--That Success Page it's last page after confirmation all thing-->
  <v-card
    tile
    elevation="0"
    color="white"
    fill-height
    class="text-center mx-auto justify-center align-center"
  >
    <div class="mx-auto justify-center align-center">
      <v-img
        src="@/assets/img/tick.png"
        contain
        width="60%"
        class="mb-8 mx-auto justify-center align-center"
      />
    </div>

    <div class="pa-4 text-center">
      <h3 class="title font-weight-light mb-2">
        {{$t('delivery.lang_welcomeToLB')}}
      </h3>

      <span class="caption grey--text">{{ $t("delivery.lang_thanksForSignup") }}</span>
    </div>
  </v-card>
</template>

<script>
export default {
  name: "SuccesAuth",
};
</script>
