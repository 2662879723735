<template>
  <v-card
    ref="form"
    tile
    elevation="0"
    color="white"
    fill-height
    class="pt-6 text-center mx-auto justify-center align-center"
  >
    <div class="justify-center">
      <div>
        <img :src="require('@/assets/img/Gruppe 2467.svg')" width="100%" />
      </div>
    </div>

    <v-sheet
      color="grey lighten-4"
      class="mt-12 pb-10 px-5"
      width="100%"
      height="100%"
      style="border-top-left-radius: 50px; border-top-right-radius: 50px;"
    >
      <h4 class="text-center font-weight-bold black--text py-12">
        {{$t('delivery.lang_register')}}
      </h4>

      <div class="text-left">
        <v-form v-model="valid" lazy-validation ref="loginForm">
          <div>
            <span class="grey--text caption">E-Mail <span class="error--text ">*</span></span>
            <v-text-field
              v-model="email"
              :rules="[rules.required, rules.email]"
              placeholder="name@example.com"
              background-color="white"
              filled
              dense
              rounded
              required
              class="rounded-lg"
            ></v-text-field>
          </div>

          <div style="margin-top: -12px;">
            <span class="grey--text caption">{{this.$t('delivery.lang_password')}}<span class="error--text ">*</span></span>
            <v-text-field
              v-model="password"
              :rules="[rules.required]"
              placeholder="*********"
              :type="(showPassword)?'text':'password'"
              :append-icon="(showPassword)?'mdi-eye':'mdi-eye-off'"
              background-color="white"
              filled
              dense
              rounded
              required
              class="rounded-lg" @click:append="showPassword=!showPassword"
            ></v-text-field>

            <div class="text-right" style="margin-top: -10px;">
              <v-btn text x-small
                style="cursor: pointer;" color="primary"
                @click="$emit('show','forgetPassword')"
              >
                {{$t('delivery.lang_forgotPassword')}}
              </v-btn>
            </div>
          </div>
        </v-form>

        <div class="my-6">
          <v-btn
            large
            depressed
            block
            color="primary" :loading="loading"
            class="text-capitalize rounded-lg" @click="fetchLogin" :disabled="!valid"
          >
            {{$t('delivery.lang_login')}}
          </v-btn>
        </div>

        <div class="caption text-center">
          <span>{{ $t('delivery.lang_iAmNewUser')}}</span>
          <small
            style="cursor: pointer;"
            class="caption primary--text"
            @click="$emit('show','register')"
          >
            {{$t('delivery.lang_Register')}}
          </small>
        </div>
      </div>
    </v-sheet>
  </v-card>
</template>

<script>

import validation from "../../mixins/validation";

export default {
  name: "Login",
  mixins:[validation],
  /*props: {
    isLogin: Boolean,
  },*/

  data() {
    return {
      showPassword:false,
      valid:false,
      email:null,
      password:null,
      loading:false,

    };
  },

  computed: {
    step: {
      get() {
        return this.$store.getters["Config/authDlg"];
      },
      set(val) {
        this.$store.commit("Config/setAuthDlg", val);
      }
    }
  },

  methods: {
    fetchLogin() {
      this.loading=true;
      if(!this.$refs.loginForm.validate())
        return ;

      this.$store.commit("Loader/changeStatus", true, { root: true }); //start global loading

      this.axios.post(`/localbee/v2/auth/login`, {
        email: this.email,
        password: this.password,
      }).then(res=>{
        if(res  && res.status === 200){
          this.$store.dispatch('auth/setAuthInfo',{
            userToken:res.data.data.token,
            loggedIn:true,
            id:res.data.data.customer.Id,
            userName:res.data.data.customer.firstname+' '+res.data.data.customer.firstname,
          })
          //SET AXIOS HEADERS
          this.axios.defaults.headers.common['Authorization'] = "Bearer "+res.data.data.token;
          this.$emit('logged')
          this.$root.$emit("logged")
        }else if(res  && res.status === 403){
          this.$swal({
            title: this.$t('delivery.lang_error'),
            icon: "error",
            text: res.data.message,
          });
          this.password=null;
        }
      }).catch(err => {
        if(err.status===500){
          this.$swal({
            title: this.$t('delivery.lang_error'),
            icon: "error",
            text: this.$t('delivery.lang_somethingWentWrongPleaseTryAgainLater'),
          });
          return;
        }
        if(err.data){
          this.$swal({
            title: this.$t('delivery.lang_error'),
            icon: "error",
            text: err.data.message,
          });

          this.password=null;
        }
      }).finally(()=> {

        this.loading=false;
        this.$store.commit("Loader/changeStatus", false, { root: true }); //start global loading
      });

    },
    // move this method where the logout button
    logout(){
      //this.logoutLoading=true;
      this.axios.post(`/localbee/v2/auth/logout`)
          .then(res=>{
            if(res && res.status===200){
              //set the auth data to null and loggedIn state to false
              this.$store.dispatch("auth/setAuthInfo", null).then(() => {
                this.axios.defaults.headers.common['Authorization'] = '';
                //when logout out redirect the user
                if(this.$route.name==='Account'){
                  this.$router.replace({
                    name: 'RestaurantMenu'
                  });
                }else if(this.$route.name==='CheckoutOrder'){
                  this.$router.go(0);
                }
              }).catch((err) => {

              });
            }
      }).catch(err=>{
        this.$swal({
          title: this.$t('delivery.lang_error'),
          icon: "error",
          text: err,
        });
      }).finally(()=>{
        //this.logoutLoading=false; // add this loading var to the loagout button's loading and diabled props to prevent multiple clicks
      })
    }
  },
  mounted() {
  }
};
</script>
